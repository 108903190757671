body{
  background: #FEFEFE;
}
p , h2{
  padding: 0;
  margin: 0;
}

header {
  min-height: 90px;
  background-color: #494d58;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
}
header>div:nth-child(1)>div{
  display: flex;
  align-items: center;
  margin-left: 12px;
  justify-content: space-between;
  width: 85px;
}
header>div:nth-child(1)>div>img:nth-child(1) {
  width: 24px;
  height: auto;
}
header>div:nth-child(1)>div>img:nth-child(2) {
  width: 50px;
  height: auto;
}
header>div:nth-child(2){
  color: #fff;
  margin-left: 5px;
}
header>div:nth-child(2)>h2{
  font-size: 1.2rem;
}
header>div:nth-child(2)>p{
  padding-top: 5px;
  font-size: 0.9rem;
}
header>div:nth-child(3){
  display: flex;
  margin-left: auto;
  align-items: center;
  padding: 0 15px 0 0;
}
header>div:nth-child(3)>div:nth-child(1)>img{
  width: 20px;
  margin-right: 13px;
  height: auto;
}
header>div:nth-child(3)>div:nth-child(2)>img{
  width: 35px;
  transform: rotate(135deg);
  height: auto;
}
.send{
  margin: 15px 0;
  display: flex;
  padding: 0 15px;
  justify-content: end;
  min-height: 5px;
}
.send p{
  padding: 15px;
  border-radius: 8px;
  background: #fef7db;
  line-height: 1.5;
}
.receive{
  margin: 15px 0;
  display: flex;
  padding: 0 15px;
  justify-content: start;
  min-height: 5px;
}
.receive>span{
  margin-right: 5px;
  width: 50px;
}
.receive p{
  border-radius: 8px;
  padding: 15px;
  line-height: 1.5;
  background: #f7f5f8;
}
.receive img{
  width: 40px;
  height: auto;
}
.time{
  padding: 5px;
  display: flex;
  align-items: end;
  font-size: 0.9rem;
  color: #C8C8C8;
  text-shadow: 0px 0px 0px grey;
}

.bottom{
  display: flex;
  position: sticky;
  bottom: 0;
}
.bottom>.input{
  margin-left: 12px;
}
.bottom>.down-arrow{
  margin-left: auto;
  margin-right: 12px;
}
.bottom>.down-arrow>a>img{
  height: auto;
  background: #919296;
  width: 45px;
  border-radius: 100%;
}
.bottom>.down-arrow>a>img:active{
  width: 47px;
}
.hide{
  display: none;
}
.messages{
  min-height: 80vh;

}
